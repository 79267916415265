<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" style="width:min-content;" v-on="on">
        <v-btn :color="color" :disabled="!editable" :class="classProps" @click="$emit('action')"
          ><v-icon class="mr-1">mdi-alert-circle</v-icon> {{ label }}</v-btn
        >
      </div>
    </template>
    <span>{{ tip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    classProps: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
